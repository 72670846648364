import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './header';
import Footer from './Footer/footer';
function App() {
  return (
    <div className="App" style={{overflowX:'hidden'}}>
    <Header/>

    <div className="row">

    <div className="col-md-4  box-container1">
<a target="_blank" href="http://businus.services" >
<img  className="sub-logo"  src={require("./images/businusServices.png")}/>
<div className="desc-div" style={{marginTop:'5px'}}> <h2 className="desc" >Enabling Business Success</h2></div>
    <img className="clipArt" src={require("./images/businusServicesClipArt.png")}/>

    </a>
    </div>

    <div className="col-md-4  box-container2">

    <a target="_blank" href="http://businus.school" >
    <img className="sub-logo" src={require("./images/businusSchool.png")}/>
    <div className="desc-div" style={{marginTop:'20px'}}><h2 className="desc" >Unlocking New Opportunities</h2></div>
    <img className="clipArt" src={require("./images/businusSchoolClipart.png")}/>
   
   
    </a>
    </div>

    <div className="col-md-4  box-container3">
<a target="_blank" href="http://businusdashboard.com" >

<img  className="sub-logo-dashboard"  src={require("./images/businusDashboardLogo.png")}/>
<div className="desc-div" style={{marginTop:'20px'}}> <h2 className="desc" >Delivering Client Experience</h2></div>

    <img className="clipArt" src={require("./images/businusDashboardClipart.png")}/>
  
    </a>
    </div>


    </div>

<Footer/>

    
    </div>
  );
}

export default App;
