import React, { Component } from "react";

let context = null;
const data = [];
export default class Header extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      opened: [],
      image:'',
      name:'',
     
    };
    context = this;
    
    
  }
  

  render() {
    return (
      <nav class="navbar  ">
          <div class="container-fluid">
            <div class="navbar-header">
              <a  href="/home">
                <img id="1" class="navbar-brand"
                  style={{ marginLeft:'2px', width:'206px',height:'57px',marginTop:'-7px',marginBottom:'3px' }}
                  src={require("./images/businus.comLogo.png")}
                />
               {/*  <h6 className="subtext">Admin Panel</h6> */}
              </a>
              
             
             
            </div>
           
         {/*    <ul class="nav navbar-nav navbar-right">
            <li className="nav-item">
                <a href="/home">Home</a>
              </li>
            <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="product-toggle" role="button" data-toggle="dropdown">
          <img  id="tab1" style={{ marginLeft: "-3px",height:'30px',marginTop:'-5px' }} src={this.state.image}/>&nbsp;{this.state.name}&nbsp;&nbsp;</a>
          <div className="dropdown-menu dropdown-menu-center dropdown-content" arealabelledby="product-toggle" >
                <ul>
             <li className="dropdown-item navmain-item" >  <a className="dropdown-item navmain-item" href= "/account" >Account</a> </li> 
             <li className="dropdown-item navmain-item">  <a  className="dropdown-item navmain-item" onClick={this.logout} href= "/" >Logout</a></li> 
                </ul>
                </div>
        </li>
      
              
            </ul>  */}
           
          </div>
         
        </nav>

    );
  }
}
